import { GetAllCodelistenResolver } from "@/router/services.js";

export const adminRoutes = {
  path: "/admin",
  component: () => import("@/views/VAdmin.vue"),
  name: "AdminPage",
  props: true,
  children: [
    {
      path: "",
      name: "AdminPageRedirect",
      redirect: { name: "Systemparameter" },
      meta: {
        title: ["Fachliche Leitstelle", "Systemparameter"],
        label: "",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "systemparameter",
      component: () => import("@/views/VAdminSystemParameters.vue"),
      name: "Systemparameter",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle", "Systemparameter"],
        label: "Systemparameter",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "konfiguration",
      component: () => import("@/views/VAdminConfiguration.vue"),
      name: "ProceedingConfiguration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle", "Konfiguration"],
        label: "Konfiguration",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "codelisten",
      component: () => import("@/views/VAdminCodeLists.vue"),
      name: "CodelistenAdministration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle", "Codelisten"],
        label: "Codelisten",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "verfahrensverwaltung",
      component: () => import("@/views/VAdminProceedings.vue"),
      name: "ProceedingAdministration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle", "Verfahrensverwaltung"],
        label: "Verfahrensverwaltung",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "transfermessages",
      component: () => import("@/views/VAdminTransferMessages.vue"),
      name: "TransferMessages",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle", "Transfer Messages"],
        label: "Transfer Messages",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "protokolle",
      component: () => import("@/views/VAdminProtocols.vue"),
      name: "Protocols",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle", "Protokolle"],
        label: "Protokolle",
        resolve: {
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
  ],
};
