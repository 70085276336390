/** @typedef {{id: string, text: string}} PageLoadingIndicator */
/** @typedef {{status: string, info: string, buttontext: string, headline: string}} IndicationModal */

/**
 * @typedef {Object} ConfirmModal
 * @property {string} id
 * @property {string} headline
 * @prop {number} infotext
 * @property {callback} callbackConfirmed
 * @prop {string} [callbackConfirmedText]
 * @prop {callback} [callbackDenied]
 * @prop {string} [callbackDeniedText
 * @prop {boolean} [isDelete]
 */

import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
import { openAPIFactory } from "@/services/open-api.js";

export const useAppStore = defineStore("app", {
  state: () => ({
    runtimeEnvironment: {
      k1: {
        url: "/api/cockpit/",
      },
      masterportal: {
        url_erfassung: "/masterportal/erfassung/",
        url_detail: "/masterportal/detail/",
        url_planwerke: "/masterportal/planwerke/",
      },
    },
    /** @type {PageLoadingIndicator[]} */
    pageLoadingIndicators: [],
    /** @type {Array} */
    popOvers: [],
    /** @type {IndicationModal[]} */
    indicationModals: [],
    /** @type {ConfirmModal[]} */
    confirmModals: [],

    /* Dictionary for loaded services */
    /**  @type {{[key: string]: boolean}} */
    resolved: {
      "/nutzer": false, // getNutzer: Nutzerinformationen
      "/verfahren": false, // getAllVerfahren: Liefert die Metadaten zu allen Verfahren, die ich sehen darf (abonniert habe).
      "/nutzer/verfahren": false, // getNutzerVerfahren: Verfahren IDs der abonnierten Verfahren
      "/konfigurationen/codelisten": false, // getAllCodelisten: Liefert alle Codelisten aus
      "/konfigurationen/verfahren": false, // getAll: Liefert(alle) VerfahrenKonfigurationen
      "/konfigurationen/blacklist": false, // liefert die Blackliste aus
    },
  }),
  actions: {
    /**
     * Loads and caches infos about the runtime environment.
     */
    loadRuntimeEnvironment() {
      axios
        .get("/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.runtimeEnvironment = response.data;
        })
        .catch(() => {
          console.error("Failed to load configuration file 'config.json'");
          alert("Could not load 'config.json'");
        });
    },
    /**
     * Loads information on the version of K1.
     * @returns {void}
     */
    async loadK1Version() {
      await openAPIFactory
        .versionResourceApiFactory()
        .getVersionInfo()
        .then((response) => {
          this.k1Version = response.data;
        })
        .catch((error) => {
          this.showErrorModal({
            response: error,
            customErrorMessage: "Abfrage der K1-Version fehlgeschlagen!",
          });
        });
    },
    /**
     * Shows an indication modal containing the composed error message.
     * @param {Object} payload
     * @returns {void}
     */
    showErrorModal(payload) {
      let errorMessage;

      if (this.resolved["/nutzer"] === false) {
        // if error exists show reason in console
        console.log(payload.customErrorMessage, " Ursache: ", payload.response.message);
        router.replace({ name: "AppAccessDenied" });
      } else {
        if (
          typeof payload.response === "object" &&
          Object.prototype.hasOwnProperty.call(payload.response, "response") &&
          Object.prototype.hasOwnProperty.call(payload.response.response, "data") &&
          Object.prototype.hasOwnProperty.call(payload.response.response.data, "message")
        ) {
          errorMessage = "Fehlermeldung: " + payload.response.response.data.message;
        } else if (
          typeof payload.response === "object" &&
          Object.prototype.hasOwnProperty.call(payload.response, "data") &&
          Object.prototype.hasOwnProperty.call(payload.response.data, "message")
        ) {
          errorMessage = "Fehlermeldung: " + payload.response.data.message;
        } else if (
          typeof payload.response === "object" &&
          Object.prototype.hasOwnProperty.call(payload.response, "data") &&
          Object.prototype.hasOwnProperty.call(payload.response.data, "statusText")
        ) {
          errorMessage = "Fehlermeldung: " + payload.response.data.statusText;
        } else if (
          typeof payload.response === "object" &&
          "status" in payload.response &&
          "statusText" in payload.response
        ) {
          errorMessage =
            "Fehlercode: HTTP/" +
            payload.response.status +
            ", Fehlermeldung: " +
            payload.response.statusText;
        } else if (typeof payload.response === "object" && "message" in payload.response) {
          errorMessage = "Fehlerbeschreibung: " + payload.response.message;
        } else {
          errorMessage = "Schwerwiegender Fehler aufgetreten.";
        }

        if (
          typeof payload.response === "object" &&
          typeof payload.customErrorMessage === "string"
        ) {
          errorMessage =
            "<span class='error'>" + payload.customErrorMessage + "</span><br>" + errorMessage;
        }

        this.showIndicationModal({
          status: "error",
          info: errorMessage,
          buttontext: "Schließen",
          headline: "Unerwarteter Fehler",
        });
      }
    },
    /**
     * Shows an indication modal.
     * @param {IndicationModal} payload
     *   The modal's content. Must feature the properties "headline", "info", "status" and "buttontext".
     * @returns {void}
     */
    showIndicationModal(payload) {
      this.indicationModals = [...this.indicationModals, payload];
    },
    /**
     * Shows an indication modal.
     * @param {ConfirmModal} payload
     *   The modal's content. Must feature the properties "headline", "info", "status" and "buttontext".
     *
     *
     *
     *
     * @returns {void}
     */
    showConfirmModal(payload) {
      this.confirmModals = [...this.confirmModals, payload];
    },

    /**
     * Shows a page loading indicator.
     * @param {PageLoadingIndicator} payload
     *   The definition of the page loading indicator to display.
     * @returns {void}
     */
    showPageLoadingIndicator(payload) {
      this.pageLoadingIndicators = [...this.pageLoadingIndicators, payload];
    },
    /**
     * Hides a specific page loading indicator.
     * @param {String} id
     *   The ID of the page loading indicator that will be hidden.
     * @returns {void}
     */
    hidePageLoadingIndicator(id) {
      this.pageLoadingIndicators = this.pageLoadingIndicators.filter((bar) => bar.id !== id);
    },
  },
  getters: {
    isResolvedRoute() {
      // compute loading status from meta-data for this page
      const resolve = router.currentRoute.value.meta.resolve ?? {};

      return Object.keys(resolve).reduce((result, value) => result && this.resolved[value], true);
    },
  },
});
