/**
 * @typedef {import('axios').CreateAxiosDefaults} CreateAxiosDefaults
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 */

/**
 * Axios basic configuration
 */

import axios from "axios";
import { keycloak } from "@/services/keycloak";

/** @type {CreateAxiosDefaults} */
const createAxiosDefaults = {
  headers: {
    "Content-type": "application/json",
  },
};

/**
 * Create the instance of Axios
 */

/** @type {AxiosInstance} */
export const axiosInstance = axios.create(createAxiosDefaults);

/**
 * Auth interceptors
 * @description Add auth token to every outgoing request.
 * @param {CreateAxiosDefaults} config
 */

export const authInterceptor = (config) => {
  const bearerToken = keycloak.token || "";

  config.headers.Authorization = `Bearer ${bearerToken}`;

  return config;
};

/** Adding the request interceptors */

export const authRequestInterceptor = axiosInstance.interceptors.request.use(authInterceptor);
