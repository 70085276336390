import { version } from "/package.json";
import { useAppStore } from "@/stores/app.js";
import { useFundamentalStore } from "@/stores/fundamental.js";

/**
 * Function to retrieve necessary data.
 * @returns {void}
 */
async function fetchInformation() {
  const appStore = useAppStore();

  await appStore.loadK1Version();
}

/**
 * Function to show app information as console info
 * @returns {void}
 */
export async function logAppInfos() {
  // in case of error stores message in variable
  let errorMsg = "";

  try {
    await fetchInformation();
  } catch (e) {
    errorMsg = e;
  }

  // variables for the output
  const fundamentalStore = useFundamentalStore();
  const versionInfo = fundamentalStore.k1Version;
  // only available after the build process in azure devops
  const placeholderMessage = "Diese Information steht nicht zur Verfügung";

  // styles for the output
  const stylesHeadline = "font-size: 14px; font-weight: 700;";
  const stylesTitle =
    "color: #2b3980; font-size: 14px; font-weight: 700; text-decoration: underline;";
  const stylesLabel = "font-weight: 700;";
  const stylesText = "font-weight: 400;";
  const stylesError = "color: #dc3545; font-weight: 700;";

  // prevent output in vitest
  if (typeof process === "object") return;

  console.groupCollapsed("%cApplication information", stylesHeadline);
  // frontend
  console.info("%cFrontend", stylesTitle);
  console.info(`%cVersion:      %c${version}`, stylesLabel, stylesText);
  console.info(`${window.location.origin}/version.txt`);
  console.info(" ");

  // backend
  if (errorMsg) console.info(`%c${errorMsg}`, stylesError);
  console.info("%cBackend", stylesTitle);
  console.info(
    `%cVersion:      %c${versionInfo.version || placeholderMessage}`,
    stylesLabel,
    stylesText
  );
  console.info(
    `%cBuild:        %c${versionInfo.build || placeholderMessage}`,
    stylesLabel,
    stylesText
  );
  console.info(
    `%cZeitpunkt:    %c${versionInfo.timestamp || placeholderMessage}`,
    stylesLabel,
    stylesText
  );
  console.info(
    `%cBranch:       %c${versionInfo.branch || placeholderMessage}`,
    stylesLabel,
    stylesText
  );
  console.info(
    `%cRevision:     %c${versionInfo.revision || placeholderMessage}`,
    stylesLabel,
    stylesText
  );
  console.groupEnd("Application information");
}

export function scrollToTop(behavior = "instant") {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior,
  });
}

export function scrollToElement(elementID, behavior = "instant", offset = -100) {
  const element = document.getElementById(elementID);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;

  window.scrollTo({ top: y, behavior: behavior });
}
