/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { VertragNeuRest } from '../models';
// @ts-ignore
import { VertragRest } from '../models';
/**
 * VertragResourceApi - axios parameter creator
 * @export
 */
export const VertragResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw. aktualisiert das Vertragsdokument (und optional dabei auch die Metadaten) an einem bestehenden Vertrag.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @summary Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw.
         * @param {VertragNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID die planID des zugehörigen Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeVertrag: async (vertrag: VertragNeuRest, file: File, planID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vertrag' is not null or undefined
            assertParamExists('erzeugeVertrag', 'vertrag', vertrag)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('erzeugeVertrag', 'file', file)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('erzeugeVertrag', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/vertrag`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (vertrag !== undefined) { 
                localVarFormParams.append('vertrag', new Blob([JSON.stringify(vertrag)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @summary Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.
         * @param {Array<File>} files 
         * @param {string} planID die planID des zugehörigen Verfahrens
         * @param {string} codeVertragsart die Art des Vertrags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadErgaenzungsdokumente: async (files: Array<File>, planID: string, codeVertragsart: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadErgaenzungsdokumente', 'files', files)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('uploadErgaenzungsdokumente', 'planID', planID)
            // verify required parameter 'codeVertragsart' is not null or undefined
            assertParamExists('uploadErgaenzungsdokumente', 'codeVertragsart', codeVertragsart)
            const localVarPath = `/verfahren/{planID}/vertrag/{codeVertragsart}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeVertragsart"}}`, encodeURIComponent(String(codeVertragsart)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VertragResourceApi - functional programming interface
 * @export
 */
export const VertragResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VertragResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw. aktualisiert das Vertragsdokument (und optional dabei auch die Metadaten) an einem bestehenden Vertrag.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @summary Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw.
         * @param {VertragNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID die planID des zugehörigen Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erzeugeVertrag(vertrag: VertragNeuRest, file: File, planID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VertragRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erzeugeVertrag(vertrag, file, planID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <b>Beschreibung:</b> Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @summary Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.
         * @param {Array<File>} files 
         * @param {string} planID die planID des zugehörigen Verfahrens
         * @param {string} codeVertragsart die Art des Vertrags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadErgaenzungsdokumente(files: Array<File>, planID: string, codeVertragsart: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VertragRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadErgaenzungsdokumente(files, planID, codeVertragsart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VertragResourceApi - factory interface
 * @export
 */
export const VertragResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VertragResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw. aktualisiert das Vertragsdokument (und optional dabei auch die Metadaten) an einem bestehenden Vertrag.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @summary Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw.
         * @param {VertragNeuRest} vertrag 
         * @param {File} file 
         * @param {string} planID die planID des zugehörigen Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeVertrag(vertrag: VertragNeuRest, file: File, planID: string, options?: any): AxiosPromise<VertragRest> {
            return localVarFp.erzeugeVertrag(vertrag, file, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
         * @summary Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.
         * @param {Array<File>} files 
         * @param {string} planID die planID des zugehörigen Verfahrens
         * @param {string} codeVertragsart die Art des Vertrags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadErgaenzungsdokumente(files: Array<File>, planID: string, codeVertragsart: string, options?: any): AxiosPromise<VertragRest> {
            return localVarFp.uploadErgaenzungsdokumente(files, planID, codeVertragsart, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VertragResourceApi - object-oriented interface
 * @export
 * @class VertragResourceApi
 * @extends {BaseAPI}
 */
export class VertragResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw. aktualisiert das Vertragsdokument (und optional dabei auch die Metadaten) an einem bestehenden Vertrag.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
     * @summary Erzeugt einen neuen Vertrag inklusive Metadaten und Vertragsdokument  bzw.
     * @param {VertragNeuRest} vertrag 
     * @param {File} file 
     * @param {string} planID die planID des zugehörigen Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragResourceApi
     */
    public erzeugeVertrag(vertrag: VertragNeuRest, file: File, planID: string, options?: AxiosRequestConfig) {
        return VertragResourceApiFp(this.configuration).erzeugeVertrag(vertrag, file, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.</br></br><b>Benötigte Rechte:</b> VERTRAG_WRITE or EIGENE_VERTRAG_WRITE or ADMIN
     * @summary Lädt Ergänzungsdokumente zu einem bestehenden Vertrag hoch.
     * @param {Array<File>} files 
     * @param {string} planID die planID des zugehörigen Verfahrens
     * @param {string} codeVertragsart die Art des Vertrags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertragResourceApi
     */
    public uploadErgaenzungsdokumente(files: Array<File>, planID: string, codeVertragsart: string, options?: AxiosRequestConfig) {
        return VertragResourceApiFp(this.configuration).uploadErgaenzungsdokumente(files, planID, codeVertragsart, options).then((request) => request(this.axios, this.basePath));
    }
}

