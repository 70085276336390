/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { PageTransferMessageUebersichtRest } from '../models';
// @ts-ignore
import { TransferMessageDetailRest } from '../models';
// @ts-ignore
import { TransferMessageStatuswechselRest } from '../models';
/**
 * TransferMessageResourceApi - axios parameter creator
 * @export
 */
export const TransferMessageResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Einzelne TransferMessage loeschen.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Einzelne TransferMessage loeschen.
         * @param {number} transferMessageID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferMessage: async (transferMessageID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferMessageID' is not null or undefined
            assertParamExists('deleteTransferMessage', 'transferMessageID', transferMessageID)
            const localVarPath = `/monitoring/transfermessages/{transferMessageID}`
                .replace(`{${"transferMessageID"}}`, encodeURIComponent(String(transferMessageID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} transferMessageID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferMessageDetail: async (transferMessageID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferMessageID' is not null or undefined
            assertParamExists('getTransferMessageDetail', 'transferMessageID', transferMessageID)
            const localVarPath = `/monitoring/transfermessages/{transferMessageID}`
                .replace(`{${"transferMessageID"}}`, encodeURIComponent(String(transferMessageID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {string} [processingtimeStart] 
         * @param {string} [processingtimeEnd] 
         * @param {string} [messagetype] 
         * @param {Array<GetTransferMessageUebersichtSource>} [source] 
         * @param {Array<GetTransferMessageUebersichtDirection>} [direction] 
         * @param {Array<GetTransferMessageUebersichtStatus>} [status] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferMessageUebersicht: async (processingtimeStart?: string, processingtimeEnd?: string, messagetype?: string, source?: Array<GetTransferMessageUebersichtSource>, direction?: Array<GetTransferMessageUebersichtDirection>, status?: Array<GetTransferMessageUebersichtStatus>, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitoring/transfermessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (processingtimeStart !== undefined) {
                localVarQueryParameter['processingtimeStart'] = (processingtimeStart as any instanceof Date) ?
                    (processingtimeStart as any).toISOString() :
                    processingtimeStart;
            }

            if (processingtimeEnd !== undefined) {
                localVarQueryParameter['processingtimeEnd'] = (processingtimeEnd as any instanceof Date) ?
                    (processingtimeEnd as any).toISOString() :
                    processingtimeEnd;
            }

            if (messagetype !== undefined) {
                localVarQueryParameter['messagetype'] = messagetype;
            }

            if (source) {
                localVarQueryParameter['source'] = source;
            }

            if (direction) {
                localVarQueryParameter['direction'] = direction;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.
         * @param {TransferMessageStatuswechselRest} transferMessageStatuswechselRest enthaelt TransfermessageIDs und den Zielstatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageStatus: async (transferMessageStatuswechselRest: TransferMessageStatuswechselRest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferMessageStatuswechselRest' is not null or undefined
            assertParamExists('updateMessageStatus', 'transferMessageStatuswechselRest', transferMessageStatuswechselRest)
            const localVarPath = `/monitoring/transfermessages/statuswechsel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transferMessageStatuswechselRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransferMessageResourceApi - functional programming interface
 * @export
 */
export const TransferMessageResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransferMessageResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Einzelne TransferMessage loeschen.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Einzelne TransferMessage loeschen.
         * @param {number} transferMessageID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransferMessage(transferMessageID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransferMessage(transferMessageID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} transferMessageID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransferMessageDetail(transferMessageID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferMessageDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransferMessageDetail(transferMessageID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {string} [processingtimeStart] 
         * @param {string} [processingtimeEnd] 
         * @param {string} [messagetype] 
         * @param {Array<GetTransferMessageUebersichtSource>} [source] 
         * @param {Array<GetTransferMessageUebersichtDirection>} [direction] 
         * @param {Array<GetTransferMessageUebersichtStatus>} [status] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransferMessageUebersicht(processingtimeStart?: string, processingtimeEnd?: string, messagetype?: string, source?: Array<GetTransferMessageUebersichtSource>, direction?: Array<GetTransferMessageUebersichtDirection>, status?: Array<GetTransferMessageUebersichtStatus>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransferMessageUebersichtRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransferMessageUebersicht(processingtimeStart, processingtimeEnd, messagetype, source, direction, status, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <b>Beschreibung:</b> Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.
         * @param {TransferMessageStatuswechselRest} transferMessageStatuswechselRest enthaelt TransfermessageIDs und den Zielstatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessageStatus(transferMessageStatuswechselRest: TransferMessageStatuswechselRest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessageStatus(transferMessageStatuswechselRest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransferMessageResourceApi - factory interface
 * @export
 */
export const TransferMessageResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransferMessageResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Einzelne TransferMessage loeschen.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Einzelne TransferMessage loeschen.
         * @param {number} transferMessageID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferMessage(transferMessageID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTransferMessage(transferMessageID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} transferMessageID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferMessageDetail(transferMessageID: number, options?: any): AxiosPromise<TransferMessageDetailRest> {
            return localVarFp.getTransferMessageDetail(transferMessageID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {string} [processingtimeStart] 
         * @param {string} [processingtimeEnd] 
         * @param {string} [messagetype] 
         * @param {Array<GetTransferMessageUebersichtSource>} [source] 
         * @param {Array<GetTransferMessageUebersichtDirection>} [direction] 
         * @param {Array<GetTransferMessageUebersichtStatus>} [status] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferMessageUebersicht(processingtimeStart?: string, processingtimeEnd?: string, messagetype?: string, source?: Array<GetTransferMessageUebersichtSource>, direction?: Array<GetTransferMessageUebersichtDirection>, status?: Array<GetTransferMessageUebersichtStatus>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTransferMessageUebersichtRest> {
            return localVarFp.getTransferMessageUebersicht(processingtimeStart, processingtimeEnd, messagetype, source, direction, status, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.
         * @param {TransferMessageStatuswechselRest} transferMessageStatuswechselRest enthaelt TransfermessageIDs und den Zielstatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessageStatus(transferMessageStatuswechselRest: TransferMessageStatuswechselRest, options?: any): AxiosPromise<void> {
            return localVarFp.updateMessageStatus(transferMessageStatuswechselRest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransferMessageResourceApi - object-oriented interface
 * @export
 * @class TransferMessageResourceApi
 * @extends {BaseAPI}
 */
export class TransferMessageResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Einzelne TransferMessage loeschen.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Einzelne TransferMessage loeschen.
     * @param {number} transferMessageID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferMessageResourceApi
     */
    public deleteTransferMessage(transferMessageID: number, options?: AxiosRequestConfig) {
        return TransferMessageResourceApiFp(this.configuration).deleteTransferMessage(transferMessageID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {number} transferMessageID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferMessageResourceApi
     */
    public getTransferMessageDetail(transferMessageID: number, options?: AxiosRequestConfig) {
        return TransferMessageResourceApiFp(this.configuration).getTransferMessageDetail(transferMessageID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {string} [processingtimeStart] 
     * @param {string} [processingtimeEnd] 
     * @param {string} [messagetype] 
     * @param {Array<GetTransferMessageUebersichtSource>} [source] 
     * @param {Array<GetTransferMessageUebersichtDirection>} [direction] 
     * @param {Array<GetTransferMessageUebersichtStatus>} [status] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferMessageResourceApi
     */
    public getTransferMessageUebersicht(processingtimeStart?: string, processingtimeEnd?: string, messagetype?: string, source?: Array<GetTransferMessageUebersichtSource>, direction?: Array<GetTransferMessageUebersichtDirection>, status?: Array<GetTransferMessageUebersichtStatus>, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TransferMessageResourceApiFp(this.configuration).getTransferMessageUebersicht(processingtimeStart, processingtimeEnd, messagetype, source, direction, status, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Ermoeglicht für mehrere TransfermessageIDs einen Statuswechsel.
     * @param {TransferMessageStatuswechselRest} transferMessageStatuswechselRest enthaelt TransfermessageIDs und den Zielstatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferMessageResourceApi
     */
    public updateMessageStatus(transferMessageStatuswechselRest: TransferMessageStatuswechselRest, options?: AxiosRequestConfig) {
        return TransferMessageResourceApiFp(this.configuration).updateMessageStatus(transferMessageStatuswechselRest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTransferMessageUebersichtSource = {
    BOP: 'BOP',
    OKTAGON: 'OKTAGON',
    CSW_T: 'CSW_T',
    DCAT_AP_PLU: 'DCAT_AP_PLU'
} as const;
export type GetTransferMessageUebersichtSource = typeof GetTransferMessageUebersichtSource[keyof typeof GetTransferMessageUebersichtSource];
/**
 * @export
 */
export const GetTransferMessageUebersichtDirection = {
    IN: 'IN',
    OUT: 'OUT'
} as const;
export type GetTransferMessageUebersichtDirection = typeof GetTransferMessageUebersichtDirection[keyof typeof GetTransferMessageUebersichtDirection];
/**
 * @export
 */
export const GetTransferMessageUebersichtStatus = {
    NEU: 'NEU',
    ERFOLGREICH: 'ERFOLGREICH',
    FEHLER: 'FEHLER'
} as const;
export type GetTransferMessageUebersichtStatus = typeof GetTransferMessageUebersichtStatus[keyof typeof GetTransferMessageUebersichtStatus];
