import type {KeycloakInitOptions} from "keycloak-js";
import Keycloak from "keycloak-js";

let refreshTimer = 0;

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: "login-required",
  checkLoginIframe: false,
  enableLogging: false,
};

export const keycloak = new Keycloak("/keycloak.json");

/**
 * Authenticate user against keycloak
 */
export async function authenticateAgainstKeycloak(): Promise<boolean> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  keycloak.onReady = function (authenticated) {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  keycloak.onAuthSuccess = function () {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  keycloak.onAuthError = function (errorData) {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  keycloak.onAuthRefreshSuccess = function () {
  };
  keycloak.onAuthRefreshError = logout;
  keycloak.onAuthLogout = logoutWithRedirect;
  keycloak.onTokenExpired = startRefreshTimer;

  const auth = await keycloak.init(keycloakInitOptions).catch((err) => {
    console.error("keycloak.init failed=", err);
  });

  return auth || false;
}

/**
 * Logout user against keycloak and redirects to LogoutPage
 */
export async function logoutWithRedirect(): Promise<void> {
  const baseURL = window.location.origin;

  const redirectUri = `${baseURL}/logout`;

  const logoutOptions = {redirectUri: redirectUri};

  await keycloak.logout(logoutOptions).catch((err) => {
    alert("LOGOUT ERR=" + err);
  });

  stopRefreshTimer();
}

/**
 * Logout user against keycloak
 */
export async function logout(): Promise<void> {
  await keycloak.logout().catch((err) => {
    alert("LOGOUT ERR=" + err);
  });

  stopRefreshTimer();
}

/**
 * Stop refresh timer
 */
export function stopRefreshTimer() {
  if (refreshTimer !== 0) {
    clearInterval(refreshTimer);
    // release refreshTimer
    refreshTimer = 0;
  }
}

/**
 * Start refresh timer to update the Keycloak token
 */
export function startRefreshTimer() {
  if (refreshTimer === 0) {
    const accessToken = keycloak.idTokenParsed;

    if (accessToken && accessToken.exp) {
      const now = Math.floor(Date.now() / 1000);
      const remainingTimeSeconds = accessToken.exp - now;

      const interval = remainingTimeSeconds * 1000;

      refreshTimer = window.setInterval(async () => {
        try {
          await keycloak.updateToken(remainingTimeSeconds);
        } catch (err) {
          await logout();
        }
      }, interval);
    }
  }
}
