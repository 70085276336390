<script>
  import { Modal } from "bootstrap";
  import { mapStores } from "pinia";
  import { DpsButton } from "diplanung-style";
  import AppModal from "@/components/AppModal.vue";
  import { useAppStore } from "@/stores/app.js";

  export default {
    name: "AppModalIndication",
    components: {
      DpsButton,
    },
    extends: AppModal,
    data() {
      return {
        refName: "modalIndication",
        bsAnimationDuration: 500,
      };
    },
    computed: {
      ...mapStores(useAppStore),
      /**
       * function to receive active indication modals
       * @returns {Array} Array of IndicationModals
       */
      activeIndicationModals() {
        return this.appStore.indicationModals;
      },
      /**
       * function to receive latest active indication modal
       * @returns {IndicationModal} IndicationModal
       */
      latestModal() {
        return this.activeIndicationModals.length
          ? this.activeIndicationModals[this.activeIndicationModals.length - 1]
          : false;
      },
      /**
       * function to receive the corresponding css class name of modal
       * @returns {string} css class name
       */
      modalType() {
        switch (this.latestModal.status) {
          case "error":
            return "modal--error";
          case "warning":
            return "modal--warning";
          case "info":
            return "modal--info";
          case "success":
            return "modal--success";
          default:
            return "modal";
        }
      },
      /**
       * function to receive the status of latest, active modal for use as css class name
       * @returns {string} css class name
       */
      typeColor: function () {
        return this.latestModal.status ? this.latestModal.status : "normal";
      },
      /**
       * function to receive the icon string depending on latest active modal status
       * @returns {string} css class name
       */
      typeIcon: function () {
        switch (this.latestModal.status) {
          case "success":
            return "dps-icon--check";
          case "info":
            return "dps-icon--info";
          default:
            return "dps-icon--warning";
        }
      },
    },
    watch: {
      activeIndicationModals(newValue) {
        this.showModal = !!newValue;

        // DOM will be ready after next tick
        this.$nextTick(() => {
          this.initiateModal();

          if (newValue) {
            setTimeout(() => this.modalInstance.show(), this.bsAnimationDuration);
          } else {
            this.modalInstance.hide();
          }
        });
      },
    },
    mounted: function () {
      this.initiateModal();

      if (!this.modalInstance) return;

      if (this.isVisible || this.showModal) this.modalInstance.show();
      this.showModal = !!this.latestModal;
    },
    methods: {
      initiateModal() {
        const modal = this.$refs[this.refName];

        // return if no modal element exists
        if (!modal) return;

        this.modalInstance = new Modal(modal);
        modal.addEventListener("hide.bs.modal", () => this.handleHide());
      },
      /**
       * function to remove last element of active modals array
       * @returns {void}
       */
      removeIndicationModal() {
        this.appStore.indicationModals.pop();
      },
    },
  };
</script>

<template>
  <Teleport to="body">
    <transition name="fade">
      <div
        v-if="latestModal"
        :ref="refName"
        class="dpw-modal-indication modal modal-info dpw-fade"
        :class="modalType"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <!-- header -->
            <div v-if="latestModal.headline" class="modal-header">
              <span v-if="typeIcon" class="dps-icon" :class="[typeIcon, typeColor]"></span>
              <span class="modal-hl" :class="typeColor">{{ latestModal.headline }}</span>
            </div>

            <!-- body -->
            <div class="modal-body">
              <!-- data from the K1 is classified as secure -->
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-if="latestModal.info" class="modal-info-text" v-html="latestModal.info"></p>
            </div>

            <!-- footer -->
            <div v-if="latestModal.buttontext" class="modal-footer">
              <DpsButton
                :title="latestModal.buttontext"
                variant="secondary"
                data-bs-dismiss="modal"
                @click="removeIndicationModal"
                @keydown.space="removeIndicationModal"
              >
                {{ latestModal.buttontext }}
              </DpsButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
