import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.js";
import { defineStore } from "pinia";
import { getPlanID } from "@/router/services";

export const useTasksStore = defineStore("tasks", {
  state: () => ({
    vtsContainer: {},
    vtsOverview: {},
    currentVtsSelection: {},
  }),
  actions: {
    setCurrentVtsSelection({ vtsCode, uvtsCode }) {
      if (!vtsCode) return;

      let newUvtsCode = uvtsCode;
      const uvtsOfNewVts = this.currentVtsSelection[vtsCode]?.uvts;

      if (!newUvtsCode && uvtsOfNewVts) {
        const activeUvts = Object.keys(uvtsOfNewVts)?.find((uvts) => uvtsOfNewVts[uvts]);

        if (activeUvts) {
          newUvtsCode = activeUvts;
        }
      } else if (!newUvtsCode && !uvtsOfNewVts) {
        newUvtsCode = vtsCode;
      }

      // Set active property to false for all items
      const setActiveFalse = () => {
        for (let key in this.currentVtsSelection) {
          this.currentVtsSelection[key].active = false;
        }
      };

      // Set uvts property to false for all items
      const setUvtsFalse = (vtsCode) => {
        for (let code in this.currentVtsSelection[vtsCode].uvts) {
          this.currentVtsSelection[vtsCode].uvts[code] = false;
        }
      };

      // Set the specified vtsCode to true
      const setActiveTrue = (vtsCode) => {
        this.currentVtsSelection[vtsCode].active = true;
      };

      // Set the specified uvtsCode to true
      const setUvtsTrue = (vtsCode, uvtsCode) => {
        this.currentVtsSelection[vtsCode].uvts[uvtsCode] = true;
      };

      // Create entry for vtsCode if not present
      if (!this.currentVtsSelection[vtsCode]) {
        this.currentVtsSelection[vtsCode] = {
          active: true,
          uvts: {},
        };
      }

      // Set active to true for specified vtsCode and false for others
      setActiveFalse();
      setActiveTrue(vtsCode);

      // Set uvtsCode to true for specified vtsCode and false for others
      if (!this.currentVtsSelection[vtsCode].uvts[newUvtsCode]) {
        setUvtsFalse(vtsCode);
        setUvtsTrue(vtsCode, newUvtsCode);
      }
    },
    /**
     * Loads VTS data by a given ID.
     *   The store's dispatch function
     *   If a string is provided, it is used as the vtsID. If an object is provided,
     *   it is expected, that vtsID is a nested property and a reload of already
     *   loaded and cached data is forced. This is necessary, since a dispatch only
     *   takes a single argument.
     * @returns {void}
     * @param vtsID
     * @param planId
     * @param forceReload
     */
    loadVtsData({ vtsID, planId, forceReload }) {
      const appStore = useAppStore();

      const planID = planId || getPlanID();

      if (
        this.vtsContainer[planID] === undefined ||
        this.vtsContainer[planID][vtsID] === undefined ||
        (forceReload && this.vtsContainer[planID][vtsID] !== "loading")
      ) {
        appStore.showPageLoadingIndicator({
          id: "loadVTS",
          text: "Einen Moment bitte, der Verfahrensteilschritt wird geladen.",
        });

        if (this.vtsContainer[planID] === undefined) {
          this.vtsContainer[planID] = {};
        }
        this.vtsContainer[planID][vtsID] = "loading";

        openAPIFactory
          .verfahrensteilschrittResourceApiFactory()
          .getVerfahrensteilschrittDetail(planID, vtsID)
          .then((response) => {
            const vtsData = response.data;

            this.setVtsData({ planID, vtsID, vtsData });
            this.loadVtsOverview(planID);

            appStore.hidePageLoadingIndicator("loadVTS");
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("loadVTS");
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Laden des Verfahrensteilschrittes ist fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Creates a new round to an existing verfahrensteilschritt
     * @param {String} planID
     *   The proceeding ID of the proceeding the new round should get added to.
     * @param {String} vtsID
     *   The ID of the VTS where the new round should get added to
     * @param {function, undefined} callbackSuccess
     *   Potential callback function for successful requests
     * @param {function, undefined} callbackError
     *   Potential callback function for request errors
     * @returns {void}
     */
    addNewRoundToVTS({ planID, vtsID, callbackSuccess }) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "addRound",
        text: "Moment. Ein neuer Durchgang wird hinzugefügt.",
      });

      openAPIFactory
        .verfahrensteilschrittResourceApiFactory()
        .createDurchgangVts(planID, vtsID)
        .then(() => {
          appStore.hidePageLoadingIndicator("addRound");
          const forceReload = true;

          this.loadVtsData({ vtsID, forceReload });
          if (callbackSuccess) {
            appStore.showIndicationModal({
              status: "success",
              // eslint-disable-next-line vue/max-len
              info: `Der Durchgang wurde angelegt.`,
              buttontext: "Schließen",
              headline: "Durchgang erfolgreich hinzugefügt",
            });
          }
        })
        .catch((error) => {
          appStore.hidePageLoadingIndicator("addRound");

          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Fehler beim Anlegen eines neuen Durchgangs aufgetreten!",
          });
        });
    },
    /**
     * Deletes a round in an existing verfahrensteilschritt
     * @param {String} planID
     *   The proceeding ID of the proceeding the new round should get added to.
     * @param {String} vtsID
     *   The ID of the VTS where the new round should get added to
     * @param {number} vtsRoundID
     *   The ID of the Round to be deleted
     * @returns {void}
     */
    deleteRoundInVTS({ planID, vtsID, vtsRoundID }) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "deleteRound",
        text: "Moment. Der Durchgang wird gelöscht.",
      });

      openAPIFactory
        .verfahrensteilschrittResourceApiFactory()
        .deleteDurchgangVts(planID, vtsID, vtsRoundID)

        .then(() => {
          appStore.hidePageLoadingIndicator("deleteRound");
          const forceReload = true;

          this.loadVtsData({ vtsID, forceReload });
        })
        .catch((error) => {
          appStore.hidePageLoadingIndicator("deleteRound");
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Fehler beim Löschen des Durchgangs aufgetreten!",
          });
        });
    },
    /**
     * Sets VTS data and triggers reactivity.
     * @param {String} planID
     *   The proceeding ID
     * @param {String} vtsID
     *   The VTS ID
     * @param {Array} vtsData
     *   Complete data of a VTS including all rounds
     * @returns {void}
     */
    setVtsData({ planID, vtsID, vtsData }) {
      const vtsContainer = JSON.parse(JSON.stringify(this.vtsContainer));

      vtsContainer[planID][vtsID] = vtsData;
      this.vtsContainer = vtsContainer;
    },
    /**
     * Set the completion status of a task
     * @param {String} planID
     *   The proceeding ID the task status should get set
     * @param vtsID
     * @param vtsRoundID
     * @param {String} aufgabenID
     *   The ID of the task the status should get set for
     * @param {Boolean} finished
     *   True if finished
     * @return {Promise}
     *   The request promise
     */
    setTaskFinished({ planID, vtsID, vtsRoundID, aufgabenID, finished }) {
      const appStore = useAppStore();

      let apiMethod;

      if (finished) {
        apiMethod = openAPIFactory.aufgabeResourceApiFactory().setAufgabeUnerledigt;
      } else {
        apiMethod = openAPIFactory.aufgabeResourceApiFactory().setAufgabeErledigt;
      }

      apiMethod(planID, vtsRoundID, aufgabenID)
        .then(() => {
          const forceReload = true;

          this.loadVtsData({ vtsID, forceReload });
        })
        .catch((error) => {
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Das Setzen des Aufgabenstatus ist fehlgeschlagen!",
          });
        });
    },
    /**
     * Loads the VTS overview data for a given plan ID.
     *
     * @param {string} planID - The ID of the plan for which to retrieve VTS overview data.
     * @returns {void}
     */
    async loadVtsOverview(planID) {
      const appStore = useAppStore();

      try {
        const response = await openAPIFactory
          .verfahrensteilschrittResourceApiFactory()
          .getVerfahrensteilschritteUebersicht(planID);

        this.vtsOverview[planID] = response.data;
      } catch (error) {
        appStore.showErrorModal({
          response: error,
          customErrorMessage: "Laden der Verfahrensteilschritte ist fehlgeschlagen!",
        });
      }
    },
    /**
     * Updates a specific action item
     * @param {String} actionItemID The ID of the action item
     * @param {Object} actionItem The details to update
     * @param {Array} files Am array of files
     * @returns {Promise<unknown>}
     */
    updateActionItem(actionItemID, actionItem, files = []) {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        openAPIFactory
          .actionItemResourceApiFactory()
          .updateActionItem(actionItem, getPlanID(), actionItemID, files)
          .then((response) => {
            this.reloadVts = response.reloadVts;

            resolve(response);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Während des Speicherns der Änderung ist ein Fehler aufgetreten!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads a single action item by its ID
     * @param {String} actionItemID
     *   The action item ID that should get loaded
     * @return {Object}
     *   The action item data
     */
    getActionItem(actionItemID) {
      const appStore = useAppStore();
      const planID = getPlanID();

      return new Promise((resolve, reject) => {
        openAPIFactory
          .actionItemResourceApiFactory()
          .findActionItem(planID, actionItemID)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Laden des ActionItems ist fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Updates a single VTS round data object and triggers reactivity.
     * @param {String} planID
     *   The proceeding ID
     * @param {String} vtsID
     *   The VTS ID
     * @param {Number} roundID
     *   The round ID
     * @param {Object} roundData
     *   The round data to update
     * @returns {void}
     */
    setVtsRound({ planID, vtsID, roundID, roundData }) {
      const vtsContainer = { ...this.vtsContainer };

      vtsContainer[planID][vtsID].splice(
        vtsContainer[planID][vtsID].findIndex(
          (round) => Number(round.durchgangszaehler) === Number(roundID)
        ),
        1,
        roundData
      );

      this.vtsContainer = vtsContainer;
    },
  },
  getters: {
    /**
     *  Reruns the codes of the currently selected VTS and UVTS
     * @returns {{uvtsCode: null, vtsCode: null}|{uvtsCode: string, vtsCode: string}}
     */
    activeVtsCodes() {
      for (let key in this.currentVtsSelection) {
        if (this.currentVtsSelection[key].active) {
          const uvts = this.currentVtsSelection[key].uvts;

          for (let uvtsKey in uvts) {
            if (uvts[uvtsKey]) {
              return { vtsCode: key, uvtsCode: uvtsKey };
            }
          }
        }
      }
      return { vtsCode: null, uvtsCode: null };
    },
    /**
     * Returns a function that retrieves the VTS data for a given VTS ID.
     * @return {function}
     *   Function that retrieves the VTS data for a given VTS ID or an empty array if not yet loaded
     */
    vtsData() {
      return (planID, vtsID) => {
        if (
          this.vtsContainer[planID] !== undefined &&
          this.vtsContainer[planID][vtsID] !== undefined &&
          this.vtsContainer[planID][vtsID] !== "loading"
        ) {
          return this.vtsContainer[planID][vtsID];
        }
        return [];
      };
    },
    /**
     * Returns a function that retrieves the highest round ID of a VTS step.
     * @return {function}
     *   Function that retrieves the highest round ID of a VTS step or undefined if not yet loaded
     */
    vtsDataLastRoundID() {
      return (planID, vtsID) => {
        const vtsData = this.vtsData(planID, vtsID);

        return vtsData.length
          ? vtsData
              .map((round) => Number(round.durchgangszaehler))
              .reduce((max, current) => (current > max ? current : max))
          : undefined;
      };
    },
    /**
     * Returns a function that retrieves the round data of a desired VTS round.
     * @return {function}
     *   Function that retrieves the round data of a desired VTS round or the highest VTS round if unspecified. Returns
     *   an empty object if not yet loaded.
     */
    vtsRoundData(planID, vtsID, roundID = undefined) {
      const vtsData = this.vtsData(planID, vtsID);
      const vtsRoundID =
        roundID !== undefined ? Number(roundID) : this.vtsDataLastRoundID(planID, vtsID);

      return vtsData.length
        ? vtsData.find((roundData) => Number(roundData.durchgangszaehler) === vtsRoundID)
        : {};
    },
  },
});
