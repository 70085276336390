import { createRouter, createWebHistory } from "vue-router";
import { startRoutes } from "@/router/routes/start.js";
import { adminRoutes } from "@/router/routes/admin.js";
import { detailRoutes } from "@/router/routes/detail.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    startRoutes,
    detailRoutes,
    adminRoutes,
    {
      path: "/impressum",
      component: () => import("@/views/VLegalNotice.vue"),
      name: "LegalNotice",
      meta: {
        title: ["Impressum"],
      },
    },
    {
      path: "/barrierefreiheit",
      component: () => import("@/views/VAccessibility.vue"),
      name: "Accessibility",
      meta: {
        title: ["Barrierefreiheit"],
      },
    },
    {
      path: "/logout",
      component: () => import("@/views/VLogout.vue"),
      name: "Logout",
      meta: {
        title: ["Logout"],
        isPublicPage: true,
        metaTags: {
          noindex: true,
          nofollow: true,
        },
      },
    },
    {
      path: "/datenschutz",
      component: () => import("@/views/VDataPrivacy.vue"),
      name: "Accessibility",
      meta: {
        title: ["Datenschutz"],
      },
    },
    {
      path: "/ueber",
      component: () => import("@/views/VAbout.vue"),
      name: "AboutPage",
      meta: {
        title: ["Über DiPlanCockpit"],
      },
    },
    {
      path: "/barrierefreiheit",
      component: () => import("@/views/VAccessibility.vue"),
      name: "AccessibilityPage",
      meta: {
        title: ["Barrierefreiheit"],
      },
    },
    // route to "Access denied Page" component
    {
      path: "/unauthorized",
      component: () => import("../components/AppAccessDenied.vue"),
      name: "AppAccessDenied",
      meta: {
        title: ["Fehlende Berechtigung"],
      },
    },
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/VNotFound.vue"),
      name: "NotFound",
      meta: {
        title: ["Seite nicht gefunden"],
      },
    },
  ],
});

export default router;
