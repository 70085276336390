<script>
  import { mapStores } from "pinia";
  import { DpsButton } from "diplanung-style";
  import AppModal from "@/components/AppModal.vue";
  import { useAppStore } from "@/stores/app.js";

  export default {
    name: "AppModalConfirm",
    components: {
      DpsButton,
      AppModal,
    },
    props: {},
    data() {
      return {
        confirmed: null,
        refName: "modalConfirm",
        visible: false,
      };
    },
    computed: {
      ...mapStores(useAppStore),
      /**
       * function to receive active indication modals
       * @returns {Array} Array of ConfirmModals
       */
      activeConfirmModals() {
        return this.appStore.confirmModals;
      },
      /**
       * function to receive latest active indication modal
       * @returns {ConfirmModal} ConfirmModal
       */
      latestModal() {
        return this.activeConfirmModals.length
          ? this.activeConfirmModals[this.activeConfirmModals.length - 1]
          : false;
      },
    },
    watch: {
      activeConfirmModals(newValue) {
        this.visible = !!newValue;
      },
    },
    methods: {
      /**
       * function to remove last element of active modals array
       * @returns {void}
       */
      removeConfirmModal() {
        this.appStore.confirmModals.pop();
      },
      onConfirm() {
        this.latestModal.callbackConfirmed();
        this.removeConfirmModal();
      },
      onCancelConfirm() {
        if (this.latestModal.callbackDenied) {
          this.latestModal.callbackDenied();
        }
        this.removeConfirmModal();
      },
    },
  };
</script>

<template>
  <Teleport to="body">
    <transition name="fade">
      <AppModal
        v-if="latestModal"
        :ref="refName"
        visible
        show-modal-header
        show-modal-footer
        as-static
        class="dpw-modal-confirm modal modal-info dpw-fade"
        :class="latestModal.isDelete ? 'modal--delete' : 'modal--warning'"
        tabindex="-1"
      >
        <!-- header -->
        <template #header>
          <span
            class="dps-icon"
            :class="latestModal.isDelete ? 'dps-icon--delete delete' : 'dps-icon--warning warning'"
          ></span>
          <span class="modal-hl" :class="latestModal.isDelete ? 'delete' : 'warning'">{{
            latestModal.headline
          }}</span>
        </template>

        <!-- body -->
        <template #default>
          <p v-if="latestModal.infotext" class="modal-info-text">
            {{ latestModal.infotext }}
          </p>
        </template>

        <!-- footer -->
        <template #footer>
          <div
            class="dps-button-group"
            role="group"
            aria-label="Buttons für Abbrechen und Übernehmen"
          >
            <DpsButton
              variant="secondary"
              :title="latestModal.callbackDeniedText"
              @click="onCancelConfirm"
              @keydown.space="onCancelConfirm"
            >
              {{ latestModal.callbackDeniedText || "Abbrechen" }}
            </DpsButton>

            <DpsButton
              :title="latestModal.callbackConfirmedText"
              @click="onConfirm"
              @keydown.space="onConfirm"
            >
              {{ latestModal.callbackConfirmedText }}
            </DpsButton>
          </div>
        </template>
      </AppModal>
    </transition>
  </Teleport>
</template>
