<script>
  import { RouterView } from "vue-router";
  import { mapStores } from "pinia";

  import router from "@/router";
  import { useAppStore } from "@/stores/app.js";
  import TheModalConfirm from "@/components/TheModalConfirm.vue";
  import TheModalIndication from "@/components/TheModalIndication.vue";
  import ThePageHeader from "@/components/ThePageHeader.vue";
  import ThePageFooter from "@/components/ThePageFooter.vue";
  import ThePageLoadingIndicator from "@/components/ThePageLoadingIndicator.vue";

  export default {
    name: "App",
    components: {
      TheModalConfirm,
      RouterView,
      TheModalIndication,
      ThePageFooter,
      ThePageHeader,
      ThePageLoadingIndicator,
    },
    props: {
      keycloak: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapStores(useAppStore),
      isPublicPage() {
        return router.currentRoute.value.meta.isPublicPage;
      },
    },
    async created() {
      await this.appStore.loadRuntimeEnvironment();
    },
  };
</script>

<template>
  <div v-if="keycloak.authenticated || isPublicPage" class="app">
    <TheModalIndication />
    <TheModalConfirm />
    <ThePageLoadingIndicator />

    <ThePageHeader
      :user-name="keycloak.idTokenParsed ? keycloak.idTokenParsed.name : 'unbekannter Nutzer'"
    />
    <RouterView />
    <ThePageFooter />
  </div>
</template>

<style lang="scss">
  .app {
    background: var(--dps-color-white);
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
  }
</style>
