/* eslint-disable vue/max-len */
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import { createPinia } from "pinia";
import HelperFunctions from "@/plugins/helper-functions.js";
// stylelint-disable-next-line scss/at-import-partial-extension
import "@/styles/applications/dpw/index.scss";
import {
  authenticateAgainstKeycloak,
  keycloak,
  startRefreshTimer,
  stopRefreshTimer,
} from "@/services/keycloak";
import { NutzerResolver, setPageTitle, userHasPermissions } from "@/router/services.js";
import { logAppInfos } from "@/services/app.js";
import { useAppStore } from "@/stores/app.js";
/* eslint-enable vue/max-len */

const app = createApp(App, { keycloak });
const pinia = createPinia();

router.beforeEach(async (to) => {
  const isPublicPage = to.matched.some((record) => record.meta.isPublicPage);

  setPageTitle(to);

  if (!isPublicPage) {
    const appStore = useAppStore();

    localStorage.setItem("isLogoutVisible", JSON.stringify(false));

    if (!keycloak.authenticated) {
      // Authenticates the user against Keycloak
      const isAuthenticated = await authenticateAgainstKeycloak();

      //Token Refresh
      if (isAuthenticated) {
        startRefreshTimer();

        await NutzerResolver();

        if (appStore.resolved["/nutzer"] === false) return false;

        if (!hasPermissions(to)) return { name: "AppAccessDenied" };

        resolveServices(to);

        await logAppInfos();
      } else {
        // defensive stop timer
        stopRefreshTimer();
      }
    } else {
      if (appStore.resolved["/nutzer"] === true) {
        resolveServices(to);
      }
    }
  }
});

const resolveServices = (to) => {
  // get meta information
  const resolve = to.meta.resolve ?? {};

  Object.entries(resolve).forEach((service) => {
    service[1]();
  });
};
const hasPermissions = (to) => {
  const requiredPermissions = to.meta.requiredPermissions ?? [];

  if (requiredPermissions.length) {
    return userHasPermissions(requiredPermissions);
  }
  return true;
};

const appMount = async (element) => {
  app.use(pinia);
  app.use(router);
  app.use(HelperFunctions);

  app.mount(element);
};

// Custom directive providing a "v-click-outside" option on elements.
app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

// Custom directive providing a "v-tab-outside" option on elements.
app.directive("tab-outside", {
  mounted(el, binding) {
    el.tabOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("keydown", el.tabOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("keydown", el.tabOutsideEvent);
  },
});

/**
 * Initializes the application by calling the appMount function.
 * @param {Function} appMount - The function responsible for mounting the application
 * @param {string} element - The DOM element selector where the application should be mounted
 * @returns {Promise<void>} - A Promise that resolves when the application is successfully initialized
 */

const initializeApp = async (appMount, element) => {
  await appMount(element);
};

function onSuccess() {
  // console.log("WeSIA successfully initialized!");
}

function onError() {
  console.error(`WeSIA ${app.version} failed to initialize Keycloak`);
}

initializeApp(appMount, "#root").then(onSuccess).catch(onError);
