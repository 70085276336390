import { openAPIFactory } from "@/services/open-api.js";
import { axiosInstance, authInterceptor, authRequestInterceptor } from "@/services/axios.js";

/**
 * get the value of a systemparameter
 * @param {string}  paramName
 * @returns {AxiosPromise<SystemParameterRest>}
 */
export async function getSystemParameter(paramName) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .systemParameterResourceApiFactory()
      .getSystemParameter(paramName)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Gets details of transfer messages based on transferMessageID passed.
 * @param {number} transferMessageID
 * @param {*} [options] Override http request option.
 * @returns {AxiosPromise<TransferMessageDetailRest>}
 */
export async function loadTransferMessageDetails({ transferMessageID, options }) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .transferMessageResourceApiFactory()
      .getTransferMessageDetail(transferMessageID, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Loads all the Protocol messages
 * @param {Object} filterParams
 * filtering params : typ,status
 * @param {number} [page] Zero-based page index (0..N)
 * @param {number} [size] The size of the page to be returned
 * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 * @param {string} [beschreibung]
 * @param {*} [options] Override http request option.
 * @returns {AxiosPromise<PageProtokollRest>}
 */
export async function loadProtocols({ filterParams, page, size, sort, beschreibung, options }) {
  const status = filterParams.Status.length ? filterParams.Status : undefined;
  const typ = filterParams.Typ.length ? filterParams.Typ : undefined;

  return new Promise((resolve, reject) => {
    openAPIFactory
      .protokollResourceApiFactory()
      .getProtokolle(status, typ, beschreibung, page, size, sort, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * get the value of a systemparameter
 * @param {string}  paramName
 * @returns {AxiosPromise<SystemParameterRest>}
 */
export async function getSystemParameterWithoutAuthentication(paramName) {
  // no authentication needed for this request
  axiosInstance.interceptors.request.eject(authRequestInterceptor);

  return new Promise((resolve, reject) => {
    openAPIFactory
      .systemParameterResourceApiFactory()
      .getSystemParameterWithoutAuthentication(paramName)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        // enable authentication
        axiosInstance.interceptors.request.use(authInterceptor);
      });
  });
}

/**
 * get the binary of a systemparameter
 * @param {string}  paramName
 * @returns {AxiosPromise<SystemParameterRest>}
 */
export async function getSystemParameterBinary(paramName) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .systemParameterResourceApiFactory()
      .getSystemparameterBinary(paramName, { responseType: "blob" })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
