/*
                              diplanung.de
    _______________________________|______________________________
   |                               |                             |
DiPlanCockpit                DiPlanBeteiligung                DiPlanPortal
=============                =================                ============
cockpit.test.diplanung.de    beteiligung.test.diplanung.de    portal.test.diplanung.de
cockpit.stage.diplanung.de   beteiligung.stage.diplanung.de   portal.stage.diplanung.de


                              diplanung.de
    _______________________________|______________________________
   |                               |                             |
DiPlanCockpit               DiPlanBeteiligung               DiPlanPortal
=============               =================               ============
hh.cockpit.diplanung.de     hh.beteiligung.diplanung.de     portal.diplanung.de
by.cockpit.diplanung.de     by.beteiligung.diplanung.de
be.cockpit.diplanung.de     be.beteiligung.diplanung.de
...

*/

interface IEnvironmentUrls {
  cockpit: string;
  beteiligung: string;
  portal: string;
}

interface IEnvironments {
  [index: "test" | "stage" | "local" | "integration" | string]: IEnvironmentUrls;
}

export const environmentUrls: IEnvironments = {
  integration: {
    cockpit: "cockpit.integration.diplanung.de",
    beteiligung: "beteiligung.integration.diplanung.de",
    portal: "portal.integration.diplanung.de",
  },
  test: {
    cockpit: "cockpit.test.diplanung.de",
    beteiligung: "beteiligung.test.diplanung.de",
    portal: "portal.test.diplanung.de",
  },
  stage: {
    cockpit: "cockpit.stage.diplanung.de",
    beteiligung: "beteiligung.stage.diplanung.de",
    portal: "portal.stage.diplanung.de",
  },
  local: {
    cockpit: "localhost:9001",
    beteiligung: "localhost:9001/beteiligung", // TODO do we have a local version?
    portal: "localhost:9001/portal", // TODO do we have a local version?
  },
};

export function getEnvironmentLinks(hostname: string = window.location.hostname): IEnvironmentUrls {
  const environment = getEnvironmentCode(hostname);

  if (environmentUrls[environment]) {
    return environmentUrls[environment];
  }

  return {
    cockpit: environment + ".cockpit.diplanung.de",
    beteiligung: environment + ".beteiligung.diplanung.de",
    portal: "portal.diplanung.de",
  };
}

export function getEnvironmentCode(hostname: string = window.location.hostname): string {
  const urlParts = hostname.split(".");

  if (hostname.includes(".test.diplanung.de")) {
    return "test";
  } else if (hostname.includes(".stage.diplanung.de")) {
    return "stage";
  } else if (hostname.includes(".integration.diplanung.de")) {
    return "integration";
  } else if (!hostname.includes(".diplanung.de")) {
    return "local";
  }

  return urlParts[0] ? urlParts[0] : "local";
}
