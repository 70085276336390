import moment from "moment";

/**
 * Determine the appointment result based on appointment data.
 * @param {Object} appointment - The appointment data.
 * @returns {Object|null} - The appointment result or null.
 */
export function getAppointmentResult(appointment) {
  if (appointment?.sitzung !== null) {
    if (appointment.sitzung.politischesErgebnis !== null) {
      return appointment.sitzung.politischesErgebnis;
    } else if (appointment.sitzung.rechtspruefungErgebnis !== null) {
      return appointment.sitzung.rechtspruefungErgebnis;
    } else if (appointment.sitzung.verwaltungstechnischesErgebnis !== null) {
      return appointment.sitzung.verwaltungstechnischesErgebnis;
    }
  }

  return null;
}

/**
 * returns proper termin or undefined according to its datumsstatus
 * @param {object} appointment Termin
 * @param datumsStatus
 * @returns {object|undefined} relevant termin
 */
export function getProperAppointment(appointment, datumsStatus) {
  if (appointment === undefined || datumsStatus === undefined) {
    return;
  }

  const type = getAppointmentType(appointment, datumsStatus);
  let selectedDate;

  switch (type) {
    case datumsStatus.stattgefunden:
      selectedDate = appointment.stattgefundenerZeitraum;
      break;
    case datumsStatus["initial prognostiziert"]:
    case datumsStatus.berechnet:
    case datumsStatus.geplant:
      selectedDate = appointment.geplanterOderBerechneterZeitraum;
      break;
    case undefined:
      selectedDate = appointment.geplanterOderBerechneterZeitraum
        ? appointment.geplanterOderBerechneterZeitraum
        : {};
  }

  return selectedDate;
}

/**
 * Returns the HEX color for the given appointment
 * @param {String} appointment appointment to check
 * @param datumsStatus
 * @returns {String} HEX color
 */
export function getAppointmentColor(appointment, datumsStatus) {
  let colorCode = "#6C6E72";

  if (
    appointment &&
    "verzoegert" in appointment &&
    "datumsstatus" in appointment &&
    appointment.datumsstatus &&
    Object.values(datumsStatus).includes(appointment.datumsstatus.code)
  ) {
    if (appointment.datumsstatus.code === datumsStatus.stattgefunden) {
      colorCode = "#00913D";
    } else if (appointment.datumsstatus.code === datumsStatus.geplant) {
      colorCode = "#04071A";
    }
  }

  return colorCode;
}

/**
 * Format a date string into a specific date format (DD.MM.YYYY).
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} - The formatted date in the format DD.MM.YYYY.
 */
export function formatTaskDate(dateString) {
  return moment(dateString).format("DD.MM.YYYY");
}

/**
 *
 * returns proper datumsstatus or undefined
 * @typedef datumsStatus
 * @type {object}
 * @property {string} stattgefunden - code for stattgefunden.
 * @property {string} geplant - code for geplant.
 * @property {string} berechnet - code for berechnet.
 * @property {string} "initial prognostiziert" - code for "initial prognostiziert".
 * @param {Object} appointment appointment to check
 * @param datumsStatus
 * @returns {String | undefined} datumsstatus.code
 */
export function getAppointmentType(appointment, datumsStatus) {
  const appointmentTypeCodesAndAssociatedDataProperty = {
    [datumsStatus.stattgefunden]: "stattgefundenerZeitraum", // 1000
    [datumsStatus.geplant]: "geplanterOderBerechneterZeitraum", // 1100
    [datumsStatus.berechnet]: "geplanterOderBerechneterZeitraum", // 1200
    [datumsStatus["initial prognostiziert"]]: "initialPrognostizierterZeitraum", // 1300
  };

  if (
    appointment?.datumsstatus?.code &&
    appointmentTypeCodesAndAssociatedDataProperty[appointment.datumsstatus.code] &&
    appointment[appointmentTypeCodesAndAssociatedDataProperty[appointment.datumsstatus.code]]
  ) {
    const dates =
      appointment[appointmentTypeCodesAndAssociatedDataProperty[appointment.datumsstatus.code]];

    if (dates.beginn || dates.ende) {
      return appointment.datumsstatus.code;
    }
  }

  return undefined;
}
